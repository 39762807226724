import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { calcEst, showEst, optionsRequired, FONT_FAMILY } from '../common/sharedFunctions';
import { colors as col } from "../components/Theme/WebTheme";
import useStyles from '../styles/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Import the images
import slide1 from '../assets/img/slide1.jpg';
import slide2 from '../assets/img/slide2.jpg';
import slide3 from '../assets/img/slide3.jpg';
import slide4 from '../assets/img/slide4.jpg';
import slide5 from '../assets/img/slide5.jpg';


const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // To track the current slide
  const auth = useSelector(state => state.auth);


  // Array to hold slide data
const slides = [
  {
    image: slide1,
    title: t('bike_delivery'),
    description: t('bike_delivery_txt'),
    buttonText: t('book_your_ride_menu'),
  },
  {
    image: slide2,
    title: t('refane_transportation'),
    description: t('refane_transportation_txt'),
    buttonText: t('book_your_ride_menu'),
  },

  {
    image: slide3,
    title: t('hilux_transportation'),
    description: t('hilux_transportation_txt'),
    buttonText: t('book_your_ride_menu'),
  },
  {
    image: slide5,
    title: t('dinah_transportation'),
    description: t('dinah_transportation_txt'),
    buttonText: t('book_your_ride_menu'),
  },
  {
    image: slide4,
    title: t('break_down'),
    description: t('break_down_txt'),
    buttonText: t('book_your_ride_menu'),
  }
];

  // Automatically cycle through the slides every 7 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 7000);
    
    return () => clearInterval(slideInterval);
  }, [slides.length]);

  useEffect(() => {
    if (auth.profile && auth.profile.uid) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.profile]);

  // Handle Next Slide
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Handle Previous Slide
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <Box
      className={classes.heroBox}
      sx={{
        backgroundImage: `url(${slides[currentSlide].image})`,
        backgroundSize: 'contain',
        height:'95vh',
        backgroundPosition: 'center',
        transition: 'background-image 1.5s ease-in-out', // Add smooth transition for background image
      }}
      style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
    >
      <Grid container spacing={3} className={classes.gridContainer} sx={{ alignItems: 'center' }}>
        
        {/* Previous Arrow */}
        <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' } }}>
          <IconButton onClick={handlePrevSlide} style={{ color: 'white' }}>
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
        </Grid>
        
        <Grid item xs={12} md={6} style={{background:col.TRANSPARENT}}>
          <Typography variant="h4" fontWeight={500} className={classes.titleMain} style={{ color: col.BLUE, fontFamily: FONT_FAMILY }}>
            {slides[currentSlide].title}
          </Typography>
          <Typography variant="h6" className={classes.subtitleMain} style={{ color: col.WHITE, fontFamily: FONT_FAMILY }}>
            {slides[currentSlide].description}
          </Typography> 
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '200px', fontSize: '16px', fontFamily: FONT_FAMILY,marginTop:10 }}
            onClick={(e) => {
              e.preventDefault();
              if (loggedIn) {
                navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings');
              } else {
                navigate('/login');
              }
            }}
          >
            {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : slides[currentSlide].buttonText}
          </Button>
        </Grid>
        
        {/* Next Arrow */}
        <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'right' }}>
          <IconButton onClick={handleNextSlide} style={{ color: 'white' }}>
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>

      {/* Responsive Controls for small screens */}
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', padding: '0 20px' }}>
        <IconButton onClick={handlePrevSlide} style={{ color: 'white' }}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={handleNextSlide} style={{ color: 'white' }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Hero;
