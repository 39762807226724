import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import ListItem from "@mui/material/ListItem";
import IconButton from '@mui/material/IconButton';
import { TextField, Button, Typography } from "@mui/material";
import { colors } from "components/Theme/WebTheme.js";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function ContactUs(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const { ...rest } = props;
    const settings = useSelector(state => state.settingsdata.settings);

    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        suggestion: "",
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.firstName.trim()) newErrors.firstName = t('required_field');
        if (!formValues.lastName.trim()) newErrors.lastName = t('required_field');
        if (!formValues.email.trim()) {
            newErrors.email = t('required_field');
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = t('invalid_email');
        }
        if (!formValues.phoneNumber.trim()) newErrors.phoneNumber = t('required_field');
        if (!formValues.suggestion.trim()) newErrors.suggestion = t('required_field');

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Submit form logic (e.g., API call)
            console.log("Form Submitted Successfully:", formValues);
            setFormValues({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                suggestion: "",
            });
        }
    };

    return (
        <div style={{ margin: '-8px' }}>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.jpg").default} />
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <br />
                    <div>
                    <h2 style={{
                        textAlign: isRTL === 'rtl' ? 'right' : 'left',
                        position: "relative",
                        marginTop: "30px",
                        minHeight: "32px",
                        color: colors.Header,
                        textDecoration: "none",
                        [isRTL === "rtl" ? "marginRight" : "marginRight"]: isRTL ? "30px" : "0px",
                        wordBreak: "break-word"
                    }}>
                        {t('contact_us')}
                    </h2>

                    {/* Company Info Section */}
                    <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 20, fontWeight: 'bold', marginTop: 30 }}>{settings.CompanyName}</p>
                    <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription} style={{ color: 'black', fontSize: 16 }}>{settings.CompanyAddress}</p>
                    {settings && settings.contact_email &&
                        <p>
                            <span className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('email')}: </span>
                            <a href={"mailto:" + settings.contact_email} className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{settings.contact_email}</a>
                        </p>
                    }
                    {settings && settings.CompanyPhone &&
                        <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('phone')}: {settings.CompanyPhone}</p>
                    }

                            <div dir={isRTL === "rtl" ? "rtl" : "ltr"} >
                        <div  style={{ display: 'flex', width: 40, marginTop: -15,[isRTL === "rtl" ? "marginRight" : "marginLeft"]: isRTL === "rtl"  ? "10px" : "-23px"}}>
                        {settings && settings.FacebookHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-facebook"
                                    title={t('follow_facebook')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton 
                                        href={settings.FacebookHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:colors.FACEBOOK_COLOR}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-facebook"}/>
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                            : null}
                        {settings && settings.TwitterHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-twitter"
                                    title={t('follow_twitter')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        href={settings.TwitterHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:colors.BLUE}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-twitter"} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                            : null}
                             {settings && settings.InstagramHandle ?
                            <ListItem className={classes.listItem}>
                                <Tooltip
                                    id="instagram-twitter"
                                    title={t('follow_instagram')}
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <IconButton
                                        href={settings.InstagramHandle}
                                        target="_blank"
                                        className={classes.navLink}
                                        style={{color:colors.VIOLET}}
                                    >
                                        <i className={classes.socialIcons + " fab fa-instagram"} />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        : null }
                    </div>
                </div>
                </div>
                    {/* Contact Form Section */}
                    <div style={{width:'40%'}}>
                    <form onSubmit={handleSubmit} style={{ marginTop: 40,marginBottom:40 }}>
                        <Typography variant="h5" style={{ marginBottom: 20,color:'black' }}>{('Have a question or need assistance? We’re here to help!')}</Typography>

                        <TextField
                            fullWidth
                            label={t('first_name')}
                            name="firstName"
                            value={formValues.firstName}
                            onChange={handleInputChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t('last_name')}
                            name="lastName"
                            value={formValues.lastName}
                            onChange={handleInputChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t('email')}
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t('phone_number')}
                            name="phoneNumber"
                            value={formValues.phoneNumber}
                            onChange={handleInputChange}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t('suggestion')}
                            name="suggestion"
                            value={formValues.suggestion}
                            onChange={handleInputChange}
                            error={!!errors.suggestion}
                            helperText={errors.suggestion}
                            variant="outlined"
                            margin="normal"
                            multiline
                            rows={4}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 20 }}
                        >
                            {t('submit')}
                        </Button>
                    </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
